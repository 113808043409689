import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../layouts/layout"
import Hero from "../modules/Hero/Hero"
import Seo from "../components/seo"
import Header from "../modules/Header/Header"
import Footer from "../modules/Footer/Footer"

const NotFoundPage = () => {
  const { wpPage, wp, wpMenu } = useStaticQuery(graphql`
    query {
      wp {
        acfOptionsGeneralSettings {
          themeSettings {
            button1 {
              buttonType
              link {
                target
                title
                url
              }
            }
            button2 {
              buttonType
              link {
                target
                title
                url
              }
            }
            largeBottomText
            fieldGroupName
            textUnderLogo
            heroImage {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
              caption
            }
            errorText
            trackers {
              script
              noscript
            }
          }
        }
      }
      wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      wpPage {
        slug
        seo {
          canonical
          cornerstone
          metaKeywords
          metaDesc
          metaRobotsNoindex
          title
        }
      }
    }
  `)

  const { slug, seo } = wpPage
  const menuItems = wpMenu?.menuItems ? wpMenu.menuItems : false
  const {
    acfOptionsGeneralSettings: {
      themeSettings: {
        heroImage,
        errorText,
        button1,
        button2,
        largeBottomText,
        fieldGroupName,
        textUnderLogo,
        trackers,
      },
    },
  } = wp

  return (
    <Layout>
      <Header data={{ button1, button2 }} slug={slug} />
      <Seo seo={seo} trackers={trackers} />
      <main>
        <Hero
          heroType="small"
          heading="404 Not Found"
          image={heroImage}
          overlap={true}
        />
        <div className="error-page container">
          <p dangerouslySetInnerHTML={{ __html: errorText }} />
        </div>
      </main>
      <Footer
        data={{ largeBottomText, fieldGroupName, textUnderLogo }}
        menuItems={menuItems}
      />
    </Layout>
  )
}

export default NotFoundPage
